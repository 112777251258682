import { DOCUMENT, isPlatformServer } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
  SecurityContext
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  ArticleData,
  CmsData,
  DocumentData,
  AuthorData
} from "@dotcom-frontend/data";
import { Store } from "@ngrx/store";
import { REQUEST, RESPONSE } from "../../../express.tokens";
import { Request } from "express";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { DynamicComponentItem } from "../../components/utils/dynamic-loader/dynamic-component-item";
import {
  setAudienceNavigation,
  setBaseHref
} from "../../store/global/global.actions";
import { GlobalState } from "../../store/global/global.reducer";
import { selectArticleFilterCategory } from "../../store/global/global.selectors";
import { ComponentLoaderService } from "../component-loader/component-loader.service";
@Injectable({
  providedIn: "root"
})
export class ContentApiService {
  epiContentApiUrl: string;
  epiContentByIDApiUrl: string;
  epiSearchApiUrl: string;
  epiArticleApiUrl: string;
  epiAuthorTeamApiUrl: string;
  epiSearchDocumentsApiUrl: string;
  epiAddDocumentViewCountApiUrl: string;
  epiAuthorIndexUrl: string;
  epiPreviewApiUrl: string;
  epiLenderListApiUrl: string;
  epiAccountManagerLocatorApiUrl: string;
  epiArticlesByAuthorApiUrl: string;
  headerData: any | undefined;
  footerData: any | undefined;
  layout: DynamicComponentItem | undefined;
  isServer = isPlatformServer(this.platformId);
  category: number | null;
  filterCategory$: Observable<number | null> = this.store.select(
    selectArticleFilterCategory
  );

  constructor(
    private http: HttpClient,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: any,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private _document: Document,
    private store: Store<{ global: GlobalState }>,
    private domSanitizer: DomSanitizer,
    private componentService: ComponentLoaderService
  ) {
    let host;

    if (this.isServer) {
      host = this.request.headers["x-forwarded-host"];
    } else {
      host = this._document.location.hostname;
    }

    host = this.domSanitizer.sanitize(SecurityContext.URL, host as string);
    this.epiContentApiUrl = `https://${host}/api/episerver/v3.0/content?contentUrl=`;
    this.epiContentByIDApiUrl = `https://${host}/api/episerver/v3.0/content/`;
    this.epiSearchApiUrl = `https://${host}/api/landg/search/`;
    this.epiArticleApiUrl = `https://${host}/api/landg/articles/parent/`;
    this.epiAuthorTeamApiUrl = `https://${host}/api/landg/articles/team/`;
    this.epiSearchDocumentsApiUrl = `https://${host}/api/landg/documents/search/`;
    this.epiAddDocumentViewCountApiUrl = `https://${host}/api/landg/documents/addview/`;
    this.epiPreviewApiUrl = `https://${host}/api/landg/preview/page/`;
    this.epiLenderListApiUrl = `https://${host}/api/landg/lenders/list/`;
    this.epiAccountManagerLocatorApiUrl = `https://${host}/api/landg/AccountManager/list/`;
    this.epiArticlesByAuthorApiUrl = `https://${host}/api/landg/articles/author/`;
    this.epiAuthorIndexUrl = `https://${host}/api/landg/Authors/`;
    this.store.dispatch(setBaseHref({ url: `https://${host}` }));
    this.category = null;
  }

  getData(url: string): Observable<any> {
    const headers = this.setHeaders();
    // TODO: LGCOM-8122 centralize sanitizing the url in one method
    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      url as string
    );

    return this.http
      .get<CmsData>(`${this.epiContentApiUrl}${safeUrl}`, {
        observe: "response",
        headers
      })
      .pipe(
        switchMap(async (res) => {
          if (res.body && res.body[0]) {
            if (this.isServer && res.body[0].cacheControlHeader) {
              this.response.setHeader(
                "Cache-Control",
                res.body[0].cacheControlHeader
              );
            }

            this.layout = await this.componentService.importComponents(
              res.body[0]
            );
            const headerData: any = res.body[0]["header"];
            if (res.body[0]["headerLinks"]) {
              this.headerData = {
                ...headerData,
                headerLinks: res.body[0]["headerLinks"]
              };
            } else {
              this.headerData = headerData;
            }
            this.footerData = res.body[0]["footer"];

            if (res.body[0]["footerCode"]) {
              this.footerData.footerCode = res.body[0]["footerCode"];
            }

            if (
              res.body[0]["footer"]?.footerDisclaimerText &&
              !res.body[0]["footer"]?.showDisclaimerAtTop
            ) {
              this.footerData.disclaimerComponent =
                await this.componentService.loadComponent(
                  "DisclaimerLayout",
                  "layouts/partials",
                  {
                    footerDisclaimerText:
                      res.body[0]["footer"].footerDisclaimerText
                  }
                );
            }

            if (headerData) {
              this.store.dispatch(
                setAudienceNavigation({ nav: headerData.audienceNavigation })
              );
            }
          } else {
            if (this.isServer) {
              this.response.statusCode = 404;
            }

            return 404;
          }

          return {
            header: this.headerData,
            footer: this.footerData,
            layout: this.layout
          };
        }),
        catchError(this.handleError<any>("getData", []))
      );
  }

  getDataById(id: number, options: any | undefined) {
    let url = id.toString();
    if (options) {
      url = `${url}?select=${options.toString()}`;
    }
    const headers = this.setHeaders();
    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      url as string
    );

    return this.http
      .get<CmsData>(`${this.epiContentByIDApiUrl}${safeUrl}`, {
        observe: "response",
        headers
      })
      .pipe(
        switchMap(async (res) => {
          if (res.body) {
            return res.body;
          } else {
            if (this.isServer) {
              this.response.statusCode = 404;
            }
            return 404;
          }
        }),
        catchError(this.handleError<any>("getDataById", []))
      );
  }

  getArticleList(
    pageStyle: string,
    articleIndexPageId: number,
    page: number
  ): Observable<any> {
    const headers = this.setHeaders();
    const urlParameters = [];
    let url;

    if (pageStyle === "authorlisting") {
      url = `${this.epiAuthorTeamApiUrl}${articleIndexPageId}`;
    } else {
      url = `${this.epiArticleApiUrl}${articleIndexPageId}`;
    }

    if (page) {
      urlParameters.push(`page=${page}`);
    }

    this.filterCategory$.subscribe((category) => {
      this.category = category;
      if (this.category && this.category > 0) {
        urlParameters.push(`filterCategory=${this.category}`);
      }
    });

    url = `${url}?${urlParameters.join("&")}`;

    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      url
    ) as string;

    return this.http
      .get<ArticleData>(safeUrl, {
        observe: "response",
        headers
      })
      .pipe(catchError(this.handleError<any>("getArticleList", [])));
  }

  search(query: string, collection: string, page: number): Observable<any> {
    const headers = this.setHeaders();
    let url = `${this.epiSearchApiUrl}content/`;
    const urlParameters = [];

    if (query) {
      urlParameters.push(`q=${query}`);
    }

    if (collection) {
      urlParameters.push(`collection=${collection}`);
    }

    if (page) {
      urlParameters.push(`page=${page}`);
    }

    url = `${url}?${urlParameters.join("&")}`;

    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      url
    ) as string;

    return this.http
      .get<ArticleData>(safeUrl, {
        observe: "response",
        headers
      })
      .pipe(catchError(this.handleError<any>("search", [])));
  }

  getDocumentList(
    documentLibraryPageId: number,
    page: number,
    sortOrder?: string,
    searchTerm?: string,
    categories?: Array<string>
  ): Observable<any> {
    const headers = this.setHeaders();
    let url = `${this.epiSearchDocumentsApiUrl}${documentLibraryPageId}`;
    const urlParameters = [];

    if (page) {
      urlParameters.push(`page=${page}`);
    }

    if (sortOrder) {
      urlParameters.push(`sortOrder=${sortOrder}`);
    }

    if (searchTerm) {
      urlParameters.push(`searchTerm=${searchTerm}`);
    }

    if (categories) {
      const categoriesString = categories.join(",");
      urlParameters.push(`categories=${categoriesString}`);
    }

    url = `${url}?${urlParameters.join("&")}`;
    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      url
    ) as string;

    return this.http
      .get<DocumentData>(safeUrl, {
        observe: "response",
        headers
      })
      .pipe(catchError(this.handleError<any>("getDocumentList", [])));
  }

  addToDocumentViewCount(contentId: number): Observable<any> {
    const headers = this.setHeaders();
    let url = this.epiAddDocumentViewCountApiUrl;

    url = `${url}${contentId}`;
    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      url
    ) as string;

    return this.http
      .post<any>(
        safeUrl,
        {},
        {
          observe: "response",
          headers
        }
      )
      .pipe(catchError(this.handleError<any>("addToDocumentViewCount", [])));
  }

  getPreviewData(url: string): Observable<any> {
    const previewId = url.split("/")[2];
    const headers = this.setHeaders();
    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      previewId as string
    );

    return this.http
      .get<CmsData>(`${this.epiPreviewApiUrl}${safeUrl}`, {
        observe: "response",
        headers
      })
      .pipe(
        switchMap(async (res) => {
          if (res.body && res.body) {
            this.layout = await this.componentService.importComponents(
              res.body
            );
            const headerData: any = res.body["header"];
            if (res.body["headerLinks"]) {
              this.headerData = {
                ...headerData,
                headerLinks: res.body["headerLinks"]
              };
            } else {
              this.headerData = headerData;
            }
            this.footerData = res.body["footer"];

            if (headerData) {
              this.store.dispatch(
                setAudienceNavigation({ nav: headerData.audienceNavigation })
              );
            }
          } else {
            if (this.isServer) {
              this.response.statusCode = 404;
            }

            return 404;
          }

          return {
            header: this.headerData,
            footer: this.footerData,
            layout: this.layout
          };
        }),
        catchError(this.handleError<any>("getPreviewData", []))
      );
  }

  setHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set("Accept-Language", "en");

    if (this.isServer && process.env["WHITELIST_SERVER_SECRET_FE"]) {
      headers = headers.set(
        "ApprovedServerSecret",
        process.env["WHITELIST_SERVER_SECRET_FE"]
      );
    }
    return headers;
  }

  getLenderList(id: string, fees: boolean, registration: boolean) {
    const urlParameters = [];
    let headers = new HttpHeaders();
    headers = headers.set("Cache-Control", "no-cache");

    if (fees) {
      urlParameters.push(`fees=${fees}`);
    }

    if (registration) {
      urlParameters.push(`registration=${registration}`);
    }

    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      `${id}?${urlParameters.join("&")}` as string
    );

    if (safeUrl) {
      return this.http
        .get<any>(`${this.epiLenderListApiUrl}${safeUrl}`, {
          headers
        })
        .pipe(
          map((response) => response),
          catchError(
            this.handleError<any>("LenderAPIService", { success: false })
          )
        );
    } else {
      return of(null);
    }
  }

  getAccountManagers(id: string, postcode: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Cache-Control", "no-cache");

    const areaCode = postcode.substring(0, 2);
    const urlParameters = [`postcode=${areaCode}`];

    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      `${id}?${urlParameters.join("&")}` as string
    );

    if (safeUrl) {
      return this.http
        .get<any>(`${this.epiAccountManagerLocatorApiUrl}${safeUrl}`, {
          headers
        })
        .pipe(
          map((response) => response),
          catchError(
            this.handleError<any>("AccountManager", { success: false })
          )
        );
    } else {
      return of(null);
    }
  }

  getAuthorList(authorIndexPageId: number, page: number): Observable<any> {
    const urlParameters = [];
    let headers = new HttpHeaders();
    headers = headers.set("Cache-Control", "no-cache");
    let url = `${this.epiAuthorIndexUrl}${authorIndexPageId}`;

    if (page) {
      urlParameters.push(`page=${page}`);
    }

    url = `${url}?${urlParameters.join("&")}`;
    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      url
    ) as string;

    return this.http
      .get<AuthorData>(safeUrl, {
        observe: "response",
        headers
      })
      .pipe(catchError(this.handleError<any>("getAuthorList", [])));
  }

  getArticlesByAuthor(authorId: number, page: number) {
    let headers = new HttpHeaders();
    headers = headers.set("Cache-Control", "no-cache");
    const urlParameters = [];

    if (page) {
      urlParameters.push(`page=${page}`);
    }

    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      `${authorId}?${urlParameters.join("&")}` as string
    );

    if (safeUrl) {
      return this.http
        .get<any>(`${this.epiArticlesByAuthorApiUrl}${safeUrl}`, {
          headers
        })
        .pipe(
          map((response) => response),
          catchError(
            this.handleError<any>("ArticlesByAuthor", { success: false })
          )
        );
    } else {
      return of(null);
    }
  }

  getBlogList(indexPageId: number, page: number) {
    const urlParameters = [];
    let headers = new HttpHeaders();
    headers = headers.set("Cache-Control", "no-cache");
    if (page) {
      urlParameters.push(`page=${page}`);
    }

    const safeUrl = this.domSanitizer.sanitize(
      SecurityContext.URL,
      `${indexPageId}?${urlParameters.join("&")}` as string
    );

    if (safeUrl) {
      return this.http
        .get<any>(`${this.epiArticleApiUrl}${safeUrl}`, {
          headers
        })
        .pipe(
          map((response) => response),
          catchError(this.handleError<any>("getBlogList", { success: false }))
        );
    } else {
      return of(null);
    }
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      if (this.isServer) {
        this.response.statusCode = error.status;
      }

      console.error(error);
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
